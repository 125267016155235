import request from '@/utils/request'

export function listPack(query) {
    return request({
        url: '/prod-api/api/index/list',
        method: 'get',
        params: query
    })
}
export function findGoods(id) {
    return request({
        url: '/prod-api/api/index/goods/'+id,
        method: 'get'
    })
}
export function claimPack(id) {
    return request({
        url: '/prod-api/api/index/claim/'+id,
        method: 'get'
    })
}
